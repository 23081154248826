import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { ConfluencePageAri } from '@atlassian/ari/confluence';

import { useContentType } from '@confluence/page-context';
import { getLogger } from '@confluence/logger';
import { SPAViewContext } from '@confluence/spa-view-context';
import { sanitizeDataForSSR } from '@confluence/ssr-utilities';
import { waitForGlobal } from '@confluence/wrm';
import { useBooleanFeatureFlag } from '@confluence/session-data';

import { LegacyBridgeQuery } from './LegacyBridgeQuery.graphql';
import { LegacyBridgeQueryV2 } from './LegacyBridgeQueryV2.graphql';
import type {
	LegacyBridgeQuery as LegacyBridgeQueryType,
	LegacyBridgeQueryVariables,
} from './__types__/LegacyBridgeQuery';
import type {
	LegacyBridgeQueryV2 as LegacyBridgeQueryV2Type,
	LegacyBridgeQueryV2Variables,
} from './__types__/LegacyBridgeQueryV2';

const logger = getLogger('LegacyBridgeNext');
export function LegacyBridgeNextInner({ cloudId, contentId }) {
	const [contentType] = useContentType();

	const ari = ConfluencePageAri.create({
		siteId: cloudId,
		pageId: contentId,
	}).toString();

	const canUseLegacyBridgeQueryV2 = Boolean(
		useBooleanFeatureFlag('confluence.frontend.legacy.bridge.query.migration_0f25n') &&
			contentType === 'page',
	);
	const { experimentFeatures } = useContext(SPAViewContext);
	const nonceValue = window.__SSR_NONCE_VALUE__;

	const {
		data: dataV2,
		loading,
		error: errorV2,
	} = useQuery<LegacyBridgeQueryV2Type, LegacyBridgeQueryV2Variables>(LegacyBridgeQueryV2, {
		variables: {
			contentId: ari,
		},
		context: { single: true },
		skip: !canUseLegacyBridgeQueryV2,
	});
	const shouldSkipLegacyBridgeQuery =
		loading ||
		(canUseLegacyBridgeQueryV2 &&
			dataV2?.singleContent?.type === 'page' &&
			(dataV2?.pageV2?.parentType === 'page' || dataV2?.pageV2?.parentType === null));

	const { data: dataV1, error: errorV1 } = useQuery<
		LegacyBridgeQueryType,
		LegacyBridgeQueryVariables
	>(LegacyBridgeQuery, {
		variables: {
			contentId: contentId || '',
			isBlog: contentType === 'blogpost',
		},
		context: { single: true },
		skip: shouldSkipLegacyBridgeQuery,
	});

	const { ptpage, content } = dataV1 || {};
	const { pageV2, singleContent: contentV2, space: spaceV2 } = dataV2 || {};
	const contentNode = content?.nodes?.[0];
	const spaceNode = contentNode?.space;
	const pageVersion = contentNode?.version?.number ?? pageV2?.version?.number;
	const parentPageId =
		ptpage?.nearestAncestors?.edges?.[0]?.node?.id ||
		(pageV2?.status === 'current' &&
			pageV2?.parentPage?.id &&
			ConfluencePageAri.parse(pageV2?.parentPage?.id).pageId) ||
		null;

	const ajsMetaParameters: { [key: string]: any }[] = [
		{ key: 'space-key', value: spaceNode?.key || spaceV2?.key },
		{ key: 'space-name', value: spaceNode?.name || spaceV2?.name },
		{ key: 'space-type', value: spaceNode?.type || spaceV2?.type },

		{ key: 'content-type', value: contentNode?.type || contentV2?.type },
		{ key: 'page-version', value: pageVersion || pageV2?.version?.number },
		{ key: 'page-title', value: contentNode?.title || pageV2?.title },
		{
			key: 'parent-page-id',
			value: parentPageId,
		},
		{
			key: 'parent-page-title',
			value:
				ptpage?.nearestAncestors?.edges?.[0]?.node?.title ||
				(pageV2?.status === 'current' ? pageV2?.parentPage?.title : null),
		},
		{ key: 'experiment-features', value: experimentFeatures },
	];
	if (contentId) {
		const createPage = pageVersion === 0;
		const pageId = createPage ? '0' : contentId;

		ajsMetaParameters.push({ key: 'content-id', value: contentId });
		ajsMetaParameters.push({ key: 'latest-page-id', value: contentId });
		ajsMetaParameters.push({ key: 'new-page', value: createPage });
		ajsMetaParameters.push({ key: 'page-id', value: pageId });
	}

	const operations = contentNode?.operations || pageV2?.operations?.results;
	if (operations) {
		const canRemovePage =
			operations.filter((item) => item?.operation === 'delete' && item?.targetType === 'page')
				.length > 0;

		ajsMetaParameters.push({ key: 'can-remove-page', value: canRemovePage });
	}

	const filteredAjsMetaParameters = ajsMetaParameters.filter(
		({ value }) => value !== undefined && value !== null,
	);

	useEffect(() => {
		waitForGlobal(
			{
				listener: 'LegacyBridgeNext.js',
				globalProperty: 'AJS.Meta.set',
			},
			() => {
				if (errorV2 || errorV1) {
					logger.error`Failed to Query Legacy AJSMetaData ${errorV2 || errorV1}`;
					return;
				}

				if (
					filteredAjsMetaParameters.length === 0 ||
					window.AJS.Meta.get('has-next-legacy-bridge')
				) {
					return;
				}

				// We have 2 legacy bridge here based on graphql
				// Another in packages/confluence-frontend-server/src/components/LegacyBridge/LegacyBridge.js based on Redux.
				// We need to let the legacy one know we have already dealt with these meta tags don't override
				window.AJS.Meta.set('has-next-legacy-bridge', true);

				filteredAjsMetaParameters.forEach(({ key, value }) => window.AJS.Meta.set(key, value));
			},
		);

		return () => {
			const ajsMeta = window.AJS?.Meta;
			ajsMeta && ajsMeta.set('has-next-legacy-bridge', false);
		};
	}, [errorV1, contentId, filteredAjsMetaParameters, errorV2]);

	if (process.env.REACT_SSR) {
		const sanitizedAJSMetaStr = sanitizeDataForSSR(JSON.stringify(filteredAjsMetaParameters));

		const legacyBridgeSSRScript =
			nonceValue !== undefined
				? `<script nonce=${nonceValue}>window.__SSR_AJS_META__="${sanitizedAJSMetaStr}"</script>`
				: `<script>window.__SSR_AJS_META__="${sanitizedAJSMetaStr}"</script>`;

		return (
			<div
				data-testid="legacyBridge-ssr"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={{ display: 'none' }}
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{
					__html: `${legacyBridgeSSRScript}`,
				}}
			/>
		);
	}

	return null;
}
